import { Component, EventEmitter, model, OnInit, Output, TemplateRef, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenusServices } from '@app/services/menus.service';
import { BaseComponent } from '@app/components/base/base.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/services/notifications.service';
import { RolesInternal, UserType } from '@app/models/roles.enum';
import { StandardQuoteService } from './standard-quote-service.service';
import { ModalService } from '@app/components/modal/modal.service';
import { AuthService } from '@app/common/auth.service';
import { WholeSale } from "@app/core/model/CoreModel";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-new-quote',
  templateUrl: './new-standard-quote.component.html',
  styleUrls: ['./new-standard-quote.component.css'],
})
export class NewStandardQuoteComponent extends BaseComponent implements OnInit {
  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;
  @Output() requestOpenModal: EventEmitter<void> = new EventEmitter<void>();

  form: FormGroup;
  isLoading: boolean = false;
  role: RolesInternal;
  module: string = '';
  idQuote: any;
  quote = [];
  dealers: { id: number, name: string, wholeSales: any[] }[] = [];
  accessories: any[] = [];
  isSetNullData: boolean = true;
  isValidForm: boolean = false;
  creationUser: string;
  isSelectedDeal: boolean = false;
  deals: any[] = [];
  id: any = 0;
  _quote: any;
  wholeSales: any[] = [];
  user: UserType;
  ready: Subject<any> = new Subject<any>();

  constructor(
    route: ActivatedRoute,
    menu: MenusServices,
    notification: NotificationService,
    public quoteService: StandardQuoteService,
    private fb: FormBuilder,
    private router: Router,
    private routee: ActivatedRoute,
    private modalService: ModalService,
    public authService: AuthService
  ) {
    super(route, menu, notification);
  }

  ngOnInit() {
    this.user = this.authService.getCurrentUserType[0];
    this.dealers = this.authService.currentDealers;
    this.module = this.sendDataToMenu();
    this.createForm();
    this.routee.paramMap.subscribe((params) => {
      this.idQuote = params.get('id');
    });
    if (this.idQuote) {
      this.getQuoteById();
    } else {
      //  this.getModels();
      if (this.quoteService.formData) {
        this.form.patchValue(this.quoteService.formData);
      }
      this.getModuleAndRole();
    }

    this.form.controls['wholeSale'].valueChanges.subscribe(value => {
      if (value)
        this.form.controls['wholeSaleId'].setValue(value.id);
    });
  }

  getModuleAndRole() {
    if (UserType.internal === this.authService.getCurrentUserType[0]) {
      let dealers = this.authService.getCurrentDealers;
      let dealer: any;
      if (this.quoteService.formData?.dealerId) {
        dealer = this.dealers
          .find((i) => i.id === this.quoteService.formData?.dealerId);
      } else {
        dealer = this.dealers
          .find((i) => i.name === 'Daimler Truck Financial Services');
      }
      this.wholeSales = dealers.filter((i: any) => i.id === dealer.id && i.wholeSales && i.wholeSales.length).map((i: any) => i.wholeSales).flat().filter((i: any) => i.marshAgencyId);
      this.form.controls['wholeSale'].setValue(this.wholeSales[0]);
      if (this.wholeSales && this.wholeSales.length && this.form.controls['wholeSaleId'].value === null) {
        this.form.controls['wholeSale'].setValue(this.wholeSales[0]);
      } else if (this.form.controls['wholeSaleId'].value) {
        const wholeSale = this.wholeSales.find((i) => i.id === this.form.controls['wholeSaleId'].value);
        if (wholeSale) {
          this.form.controls['wholeSale'].setValue(wholeSale);
          this.form.controls['wholeSaleId'].setValue(wholeSale.id);
        }
      }
      this.form.controls['dealer'].setValue(dealer);
      this.form.controls['dealerId'].setValue(dealer.id);
    } else {
      this.getRelationalName()
      const dealer = this.dealers.find((i) => i.id === this.authService.getCurrentDealerId) || { id: 0 };
      this.form.controls['dealer'].setValue(dealer);
      this.form.controls['dealerId'].setValue(dealer.id);

    }

  }

  getQuoteById() {
    this.isLoading = true;
    this.quoteService.getById(this.idQuote).subscribe({
      next: (response) => {
        response.data.model.priceType =
          response.data.standardQuoteModel.priceType;
        this.creationUser = response.data.creationUser;
        this.form.patchValue(response.data);
        this.setAccessoriesValues(response.data.accessories);
        let price = response.data.standardQuoteModel.price;
        let model = response.data.model;
        model.price = price;
        this.form.controls['model'].setValue(model);
        this.form.controls['quantity'].setValue(response.data.quantity);
        this.ready.next({
          ...response.data.model,
          quantity: response.data.quantity,
          creationUser: response.data.creationUser,
          hasDeals: response.data.standardQuoteDeals.length > 0,
        });
        this.quoteService.formData = response.data;
        this.isLoading = false;
        //this.getModels();
        if (this.creationUser != this.authService.currentUserIds) {
          this.form.controls['companyName'].disable();
          this.form.controls['quoteName'].disable();
          this.form.controls['dealerId'].disable();
          this.form.controls['wholeSaleId'].disable();
        }
        this.getModuleAndRole();
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  models: any[] = [];
  createForm() {
    this.form = this.fb.group({
      companyName: [, [Validators.required]],
      quoteName: [, [Validators.required]],
      dealer: [, []],
      dealerId: [, []],
      id: [, []],
      customer: [, [Validators.required]],
      model: [, [Validators.required]],
      standardQuoteDeals: [[], Validators.required],
      accessories: this.fb.array([]),
      creationDate: [, []],
      updatedDate: [, []],
      creationUser: [, []],
      status: [, []],
      dealAmount: [, []],
      quantity: [, [Validators.required]],
      wholeSale: [, [Validators.required]],
      wholeSaleId: [, [Validators.required]],
    });

    // let initializing = true;

    this.form.valueChanges.subscribe((value) => {
      this.deals = this.form.controls['standardQuoteDeals'].value;
      this.isValidForm =
        this.form.controls['model'].valid &&
        this.form.controls['customer'].valid &&
        this.form.controls['quantity'].valid &&
        this.form.controls['companyName'].valid &&
        this.form.controls['quoteName'].valid &&
        this.form.controls['dealer'].valid;

      if (this.deals && this.deals.length > 0)
        this.isSelectedDeal = this.deals.some(
          (deal) => deal.isSelected == true
        );
    });
  }

  getRelationalName() {
    const dealerId = this.authService.getCurrentDealerId;
    this.quoteService.getRelationalName(dealerId).subscribe((response) => {
      this.dealers = response.data;
      if (this.dealers.length === 1) {
        if (this.dealers[0].wholeSales.length == 1) {
          this.form.controls['wholeSale'].setValue(this.dealers[0].wholeSales[0])
        } else {
          this.wholeSales = this.dealers[0].wholeSales
        }
      } else {
        this.wholeSales = this.dealers.find((i) => i.id == dealerId)?.wholeSales ?? [];
      }
    })
  };

  onChangeDealer() {
    const dealer = this.form.controls['dealer'].value
    this.form.controls['dealerId'].setValue(dealer.id)
    this.wholeSales = []
    let filter: WholeSale[] = this.dealers.filter((i: any) => i.id == dealer.id).map((i: any) => i.wholeSales).flat().filter((i: any) => i.marshAgencyId);
    if (filter && filter.length > 0) {
      this.form.controls['wholeSale'].setValue(filter[0])
      this.wholeSales = filter;
    } else {
      this.form.controls['wholeSale'].setValue(null);
    }
  }


  showWholeSale(wholeSale: WholeSale) {
    if (wholeSale.key && wholeSale.name) {
      return (wholeSale.key + " - " + wholeSale.name);
    } else {
      return ""
    }
  }


  setAccessoriesValues(accessoriesData: any[]) {
    const accessoriesArray = this.form.get('accessories') as FormArray;
    accessoriesArray.clear();

    accessoriesData.forEach((accessory) => {
      const accessoryFormGroup = this.fb.group({
        id: [accessory.id],
        price: [accessory.price],
        classification: [accessory.classification],
        year: [accessory.year],
        model: [accessory.model],
        quantity: [accessory.quantity],
      });
      accessoriesArray.push(accessoryFormGroup);
    });
  }

  update(isDownload?: boolean) {
    this.quoteService.formData = this.form.getRawValue();

    this.setValueInService();
    this.isLoading = true;
    const userId = this.authService.currentUserIds;
    if (userId !== this.form.controls['creationUser'].value) {
      if (isDownload) this.downloadPdf();
      return;
    }
    this.quoteService.updateQuote(this.form.controls['id'].value).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.showNotificationSuccess(
          'La cotización se ha guardado correctamente.'
        );
        if (isDownload) this.downloadPdf();
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error al momento de guardar la cotización.'
        );
        this.isLoading = false;
      },
    });
  }


  isEditingMode(): boolean {
    return this.deals.length > 0 && this.idQuote;
  }

  saveDraft() {
    this.isLoading = true;
    this.quoteService.saveDraft().subscribe({
      next: (response) => {
        this.isLoading = false;
        // this.setResponseOnForm(response.data);
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  downloadPdf() {
    this.isLoading = true;
    this.quoteService.downloadPdf(this.idQuote).subscribe({
      next: (blob: Blob) => {
        this.saveAsPdf(blob, `Cotizacion_${this.idQuote}`);
        this.isLoading = false;
      },
      error: (blob: Blob) => {
        this.isLoading = false;
        this.showNotificationError(
          'Ha ocurrido un error no se pudo descargar pdf.'
        );
      },
    });
  }

  setValueInService() {
    this.setValueDownpaymentAndOpeningfee();
    this.quoteService.formData = this.form.getRawValue();
  }

  setValueDownpaymentAndOpeningfee() {
    let deals = this.form.controls['standardQuoteDeals'].value;
    let newDeals = deals.map((i: any) => {
      return {
        ...i,
        financialSetup: {
          ...i.financialSetup,
          downPayment: i.downPayment,
          downPaymentTax: i.downPaymentTax,
          downPaymentTaxType: i.downPaymentTaxType,
          openingFee: i.openingFee,
          openingFeeTax: i.openingFeeTax,
          openingFeeTaxType: i.openingFeeTaxType,
          termId: i.financialCondition.id,
        },
      };
    });
    this.form.controls['standardQuoteDeals'].patchValue(newDeals);
    this.modalService.closeModal();
  }

  cancel() {
    this.router.navigate(['/quote/standard-quote']);
  }

  ngOnDestroy() {
    this._quote = null;
  }

  setQuantity(quantity: any) {
    this.form.controls['quantity'].setValue(quantity);
  }

  status() {
    const form = this.form;
    for (const controlName of Object.keys(form.controls)) {
      const control = form.get(controlName);

      if (control && control.invalid) {
      }
    }
  }

  updateDeal(deals: any) {
    this.form.controls['standardQuoteDeals'].reset();
    this.form.controls['standardQuoteDeals'].setValue(deals);
    this.deals = deals;
    this.quoteService.formData = this.form.getRawValue();
    // this.update()
  }


  openModal() {
    if (this.isEditingMode()) {
      this.modalService.component = this.modalTemplate;
      this.modalService.openModal();
    }
  }

  onInputFocus() {
    this.openModal();
  }

  openRequestedModal() {
    this.openModal();
  }

  cancelChanges() {
    if (!this.quoteService.formData) return;
    this.form.patchValue(this.quoteService.formData);
    this.modalService.closeModal();
  }

  deleteAllDeals() {
    this.modalService.closeModal();
    this.isLoading = true;
    this.quoteService.deleteAllDeals(this.idQuote).subscribe({
      next: (response) => {
        this.form.controls['standardQuoteDeals'].setValue([]);
        this.setValueInService();
        this.showNotificationSuccess(
          'Las propuestas se han eliminado correctamente.'
        );
        this.isLoading = false;
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error al momento de eliminar las porpuestas.'
        );
        this.isLoading = false;
      },
    });
  }

  updateForm(value: any) {
    this.form.patchValue(value);
  }

}
