import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { StandardQuoteService } from '../../standard-quote-service.service';
import {
  FinancialProductEnum,
  grace,
  payments,
  specialPayments,
} from '../../model/standard-quote.model';
import { formatDate } from '@angular/common';
import { ModalService } from '@app/components/modal/modal.service';
import { BaseComponent } from '@app/components/base/base.component';
import { Payment } from '../../model/standard-payments.model';
import { ActivatedRoute } from '@angular/router';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';

@Component({
  selector: 'app-payments-standard-quote',
  templateUrl: './payments-standard-quote.component.html',
  styleUrls: ['./payments-standard-quote.component.css'],
})
export class PaymentsStandardQuoteComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @ViewChild('specialTemplate') specialTemplate!: TemplateRef<any>;

  @Input() payments: payments[];
  @Input() specialPayments: specialPayments[];
  @Input() grace: grace[];
  @Input() idQuote: any;
  @Input() idDeal: any;
  @Input() isEdit: boolean;
  @Input() result: any;
  @Input() tipoProductoFinanciero: FinancialProductEnum;

  @Output() updateSpecialPayments: EventEmitter<any> = new EventEmitter();
  @Output() updateGrace: EventEmitter<any> = new EventEmitter();

  quantity: number;
  period: any;
  isLoading: boolean = false;

  constructor(
    route: ActivatedRoute,
    menuService: MenusServices,
    notification: NotificationService,
    private quoteService: StandardQuoteService,
    private modalService: ModalService
  ) {
    super(route, menuService, notification);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['payments'] || changes['specialPayments'] || changes['grace']) {
      if (this.payments.length > 0 && this.specialPayments.length > 0) {
        this.payments.forEach((payment) => {
          const correspondingSpecialPayment = this.specialPayments.find(
            (specialPayment) => specialPayment.period === payment.period
          );
          if (correspondingSpecialPayment) {
            payment.paymentType = 'SPECIAL_PAYMENT';
          }
        });
      }

      if (this.payments.length > 0 && this.grace.length > 0) {
        this.payments.forEach((payment) => {
          const correspondingSpecialPayment = this.grace.find(
            (grace) => grace.period === payment.period
          );
          if (correspondingSpecialPayment) {
            payment.paymentType = 'GRACE_PERIOD';
          }
        });
      }
    }
  }

  ngOnInit() {
    this.quantity = Number(this.quoteService.formData?.quantity);
  }

  formatDate(date: any) {
    if (date) return formatDate(date, 'MMM-yyyy', 'es-Es')?.toUpperCase();
    return '';
  }

  openModal(period: any) {
    let periodSelected: any;

    if (period.paymentType == 'SPECIAL_PAYMENT') {
      periodSelected = this.specialPayments.find(
        (i) => i.period == period.period
      );
    } else if (period.paymentType == 'GRACE_PERIOD') {
      periodSelected = this.grace.find((i) => i.period == period.period);
    }

    let model = {};

    if (periodSelected) {
      model = new Payment(periodSelected, period.paymentDate);
    } else {
      model = {
        id: null,
        period: period.period,
        paymentDate: period.paymentDate,
        paymentType: null,
      };
    }
    this.period = model;
    this.modalService.component = this.specialTemplate;
    this.modalService.openModal();
  }

  update(payment: any) {
    if (payment.paymentType == 'SPECIAL_PAYMENT') {
      this._updateSpecialPayment(payment);
    } else if (payment.paymentType == 'GRACE_PERIOD') {
      this._updateGrace(payment);
    }
  }

  _updateGrace(grace: any) {
    let graceSelected = {
      gracePeriodDate: grace.paymentDate,
      ...grace,
    };
    if (graceSelected.id) {
      const index = this.grace.findIndex((i) => i.id == grace.id);
      this.grace[index] = graceSelected;
    } else {
      this.grace.push(graceSelected);
    }

    this.updateGrace.emit(this.grace);
  }

  _updateSpecialPayment(specialPayment: any) {
    let specialPaymentSelected = {
      ...specialPayment,
      specialPaymentDate: specialPayment.paymentDate,
    };
    let amount = 0;
    this.specialPayments.map((i) => amount += i.amount);
    amount = Number(amount) + Number(specialPayment.amount);
    if(amount > this.result.financialAmount ){
      this.showNotificationError('La suma de los montos ingresados excede el monto a financiar.');
    }else{
      if (specialPaymentSelected.id) {
        const index = this.specialPayments.findIndex(
          (i) => i.id == specialPaymentSelected.id
        );
        this.specialPayments[index] = specialPaymentSelected;
      } else {
        this.specialPayments.push(specialPaymentSelected);
      }

      this.updateSpecialPayments.emit(this.specialPayments);
    }
  }

  delete(period: any) {
    if (period.paymentType == 'SPECIAL_PAYMENT') {
      this.deleteSpecialPayment(period);
    } else if (period.paymentType == 'GRACE_PERIOD') {
      this.deleteGrace(period);
    }
  }

  deleteSpecialPayment(period: any) {
    if (!period.id) {
      this.specialPayments = this.specialPayments.filter(
        (i) => i.id != period.id
      );
      this.updateSpecialPayments.emit(this.specialPayments);
    }

    this.isLoading = true;
    this.quoteService
      .deleteSpecialPayments(this.idQuote, this.idDeal, period.id)
      .subscribe({
        next: (response) => {
          this.specialPayments = this.specialPayments.filter(
            (i) => i.id != period.id
          );
          this.updateSpecialPayments.emit(this.specialPayments);
          this.showNotificationSuccess(
            'Se ha eliminado con éxtio el pago especial.'
          );
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.showNotificationError(
            'Ha ocurrido un error al eliminar pago especial.'
          );
        },
      });
  }

  deleteGrace(period: any) {
    if (!period.id) {
      this.grace = this.grace.filter((i) => i.id != period.id);
      this.updateGrace.emit(this.grace);
    }
    this.isLoading = true;
    this.quoteService
      .deleteGrace(this.idQuote, this.idDeal, period.id)
      .subscribe({
        next: (response) => {
          this.grace = this.grace.filter((i) => i.id != period.id);
          this.updateGrace.emit(this.grace);
          this.showNotificationSuccess(
            'Se ha eliminado con éxtio el período de gracia.'
          );
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.showNotificationError(
            'Ha ocurrido un problema al eliminar período de gracia.'
          );
        },
      });
  }

  isFinancialLeasing(){
    return this.tipoProductoFinanciero == FinancialProductEnum.financialLeasing;
  }

  ngOnDestroy() {
    this.modalService.component = null;
    this.modalService.closeModal();
  }

  hasPeriodGrace(period: number) {
    return this.grace.find((i) => i.period === period) !== undefined;
  }
  hasSpecialPayment(period: number): boolean {
    return this.specialPayments.find((i) => i.period === period) !== undefined;
  }
}
